import "./App.css"

function App() {
  return (
    <div className="App">
        <p>
          We are <b>Macha Software Solutions</b> <br /><br />
          We have expertise in the following technologies and can help you build quality products powered by software:<br />
          AWS, GCP, Solana, EVM based chains, Rust, JS, React<br /><br />
          We are particularly interested in solving issues related to scale.<br /><br />
          <a href="emailto:contact@machasolutions.com">Contact us</a>
        </p>
    </div>
  );
}

export default App;
